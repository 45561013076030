#elipse-data-img
{
    margin-top: -40px;
}

div#food-images-container>img
{
    position: absolute;
}

div#food-images-container
{
    position: relative;
    margin-top: 30px;
    min-height: 40vh;

    img#plato-hummus-mob {
        width: 14rem;
        right: -30px;
    }

    img#morron-mob
    {
        width: 150px;
    }

    img#morron-mob,
    img#perejil-mob {
        left: -10px;
    }

    img#perejil-mob {
        margin-top: 10rem;
    }

    img#garbanzo-mob {
        left: 40%;
        transform: translateX(-50%);
        margin-top: 10rem;
    }
}

.flex
{
    display: flex;
    justify-content: center;
}

img#helena-hummus-elipse
{
    width: 80%;
}

img.desktop-d-none
{
    display: none;
}

@media screen and (min-width: 768px) {
    div#food-images-container>img,
    div#food-images-container
    {
        display: none;
    }
    img#helena-hummus-elipse
    {
        margin-top: 5rem;
        width: 40%;
    }
    img#garbanzo-desktop
    {
        display: block;
        width: 80px;
        height: 100px;
        position: relative;
        top: 5rem;
    }
    img#pan-pita-2-desktop
    {
        display: block;
        position: absolute;
        right: 0;
        width: 250px;
        margin-top: 10rem;
    }
    img#morron-2-desktop
    {
        display: block;
        position: absolute;
        right: 5rem;
        width: 12rem;
        margin-top: 25rem;
    }
    img#hummus_blanco_desktop
    {
        display: inline;
        width: 25%;
        position: absolute;
        left: 0;
        margin-top: 5rem;
    }
}