div.rrss-cont {
    margin: 0.5rem auto 2rem;
    text-align: center;
    display: flex;
    justify-content: center;

    p {
        color: #ffffff;
        font-size: 22px;
    }

    .uppercase {
        text-transform: uppercase;
    }

    img {
        width: 20px;
    }

    .mt-2{
        margin-top: 2rem;
    }

    .social-media {
        display: flex;
        gap: 0.25rem;
    }
}

@media screen and (min-width: 768px) {
    img#ajo_desktop-desktop {
        display: inline;
        width: 230px;
    }
    
    .ml-5{
        margin-left: 5rem;
    }

    #center-div {
        width: 20%;
    }

    img#perejil-bottom-img {
        display: block;
        width: 140px;
        height: 100px;
        position: relative;
        bottom: -150px;
        right: 10rem;
    }
}
