@font-face {
    font-family: 'RollerCoresRegular';
    src: url('../assets/fonts/Roller_Cores_Regular.otf') format('opentype'),
        url('../assets/fonts/Roller_Cores_Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'HelveticaNeueBold';
    src: url('../assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'HelveticaNeueThin';
    src: url('../assets/fonts/HelveticaNeueCyr-Thin.ttf') format('truetype');
}

.helvetica
{
    font-family: 'HelveticaNeue';
}

.helvetica-bold
{
    font-family: 'HelveticaNeueBold';
}

.rollerCoresReg
{
    font-family: 'RollerCoresRegular';
}

.helvetica-thin
{
    font-family: 'HelveticaNeueThin';
}

.text-center
{
    text-align: center;
}

.white
{
    color: #ffffff;
}

.font-2rem{
    font-size: 2.5rem;
}