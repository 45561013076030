.description {
  text-align: center;
  p {
    color: #ffffff;
    line-height: 28px;
  }

  .my-0 {
    margin: 0;
  }

  img.dsktp-d-none {
    display: none;
  }

  #images-bg-food {
    height: 30vh;
    position: relative;
    width: 102vw;
    margin-left: -10px;

    img {
      width: 140px;
      position: absolute;
    }

    img#pan-pita-1 {
      left: 0;
    }

    img#pan-pita-2 {
      width: 170px;
      right: -15px;
      margin-top: 4.5rem;
    }

    img#perejil-img {
      width: 120px;
      margin-top: -30px;
      right: 0;
    }

    img#ajo-img {
      left: 40%;
      transform: translateX(-50%);
      bottom: -10%;
    }

    img#garbanzo_bg {
      width: 75px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: 768px) {
    #images-bg-food {
      display: none;
    }

    p {
      font-size: 20px;
      line-height: 36px;
    }

    img#hummus-desktop {
      position: absolute;
      right: 0;
      width: 215px;
      display: block;
      margin-top: -40px;
    }

    img#pan-pita-desktop-1 {
      display: block;
      position: absolute;
      left: 0;
      width: 150px;
    }
  }

  @media screen and (min-width: 1024px) {
    p {
      font-size: 24px;
    }

    img#hummus-desktop {
      width: 250px;
      margin-top: -45px;
    }
  }
}
