.flex
{
    display: flex;
}

.justify-center
{
    justify-content: center;
}

div.props-container
{
    max-width: 75%;
    margin: 2.5rem auto;
}

div.props-container
{
    img
    {
        margin: 1rem;
        max-height: 90px;
    }
}

.owl-carousel .owl-item img.item{
    width: 70%;
}

.owl-carousel .owl-item
{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 678px) {
    div.props-container
    {
        max-width: 90%;
        margin: 2.5rem auto;
    }

}

@media screen and (min-width: 1200px) {
    .owl-carousel .owl-item img.item
    {
        width: 55%;
    }
}