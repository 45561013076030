body{
    background: linear-gradient(90deg, #E37413 0%, #F6A739 39.06%, #F6A739 67.71%, #E37413 100%);
}

/*div que engloba al logo*/
.logo{ 
    text-align: center;
    width: 100%;
    height: auto;
}

.img-logo{
    width: 50%;
    height: auto;
}

.products{
    width: 95%;
    height: auto;
    margin: 0 auto;
}

.grabygo{
    width: 22%;
    height: auto;
    left: 120px;
    margin-bottom: -8%;
}

.product{
    width: 74%;
    height: auto;
    display:block;
    margin:auto;
}

.headerText{
    display: none;
}

.m-0
{
    margin: 0
}

.mt-0
{
    margin-top: 0;
}


@media(max-width: 768px){
    .img-logo{
        width: 76%;
    }
    .headerText{
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        text-align: center;
        font-family: 'Helvetica Neue';
        margin: 2rem auto 0 auto;
        width: 100%;
        height: auto;
    }
    .titleImg{
        width: 76%;
        height: auto;
        margin: 0 auto;
        margin-bottom: -6%;
    }
    .text{
        font-family: 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 6.5vw;
        text-align: center;
        color: #EBE2B6;
        text-shadow: 0px 4.4613px 4.4613px rgba(0, 0, 0, 0.2);
    }
    .text2{
        color: #ffffff;
    }
    .products{

    }
    .grabygo{

    }
    .product{

    }
}


@media(min-width: 769px){
    .logo{
        animation: slideInDown;
        animation-duration: 1.5s;
        -o-animation-duration: initial;
    }
    .product{
        animation: fadeInLeft; 
        animation-duration: 1.5s;
        -o-animation-duration: initial;
    }
    .grabygo{
        animation: fadeInLeft; 
        animation-duration: 1.5s;
        -o-animation-duration: initial;
    }
    img#morron-2-desktop:hover,
    /*img#hummus_blanco_desktop:hover,*/
    img#garbanzo-desktop:hover,
    img#pan-pita-2-desktop:hover,
    img#ajo_desktop-desktop:hover
    {
        animation: bounce; 
        animation-duration: 1s;
        -o-animation-duration: initial;
    }
    img#helena-hummus-elipse:hover{
        animation: swing;
        animation-duration: 1.5s;
        -o-animation-duration: initial;
    }
}