#mob-prefooter
{
    margin-top: 1rem;

    img#plato-hummus-mob
    {
        position: relative;
        left: -10px;
    }

    img#ingredients-hummus-mob
    {
        position: absolute;
        width: 180px;
        right: -25px;
        margin-top: 2rem;
    }
}

